// Trumps / Colors

// Adjunto código para actualizar en trumps, según yo ya esta bulletproof, para no tener conflictos, poco a poco dejaremos de usar la variables marcadas como fixes, las cuales deje solo para que no haya conflictos de variables inexistentes.

// Colors Framework
$background-theme: #e4edf2;
// Neutrals
$white: #ffffff;
$gray1: #e4edf2;
$gray2: #d6e3e8;
$gray3: #bfd0d8;
$gray4: rgba(0, 0, 0, 0.1);
$dark1: #889aa6;
$dark2: #62737d;
$dark3: #515f66;
$dark4: #3c474c;

$transparent5: rgba($dark4, 0.05);
$transparent10: rgba($dark4, 0.1);
$transparent20: rgba($dark4, 0.2);
$transparent50: rgba($dark4, 0.5);
$transparent: transparent;

// Primary
$primary-dark: #57cc99;
$primary: #35dba4;
// $primary-light: opacity($primary, 0.2);
$primary-light: rgba($primary, 0.2);
$primary-gradient: linear-gradient($primary, $primary-dark);
$primary-gradient-alt: linear-gradient(to right, $primary-light, $primary-dark);
$primary-transparent5: rgba($primary, 0.05);

// Premium
$premium-dark: #ffb871;
$premium: #ffd073;
$premium-light: rgba($premium, 0.2);
$premium-gradient: linear-gradient($premium, $premium-dark);

// Info
$info-dark: #3a86ff;
$info: #3eb6ff;
$info-light: rgba($info, 0.2);
$info-gradient: linear-gradient($info, $info-dark);

// Help
$help-dark: #ff5d8f;
$help: #ff5fb3;
$help-light: rgba($help, 0.2);
$help-gradient: linear-gradient($help, $info-dark);

// Featured
$featured-dark: #5e76cc;
$featured: #606acc;
$featured-light: rgba($featured, 0.2);
$featured-gradient: linear-gradient($featured, $featured-dark);

// Warning
$warn-dark: #ee6c4d;
$warn: #ed644f;
$warn-light: rgba($warn, 0.2);
$warn-gradient: linear-gradient($warn, $warn-dark);

// Danger
$danger-dark: #f23b5a;
$danger: #ef3c45;
$danger-light: rgba($danger, 0.2);
$danger-gradient: linear-gradient($danger, $danger-dark);

// Marketing gradients
$purple-blue: linear-gradient($featured, $info-dark);
$blue-green: linear-gradient($info, $primary-dark);
$green-yellow: linear-gradient($primary, $premium-dark);
$yellow-orange: linear-gradient($premium, $warn-dark);
$orange-red: linear-gradient($warn, $danger-dark);
$red-pink: linear-gradient($danger, $help-dark);
$pink-purple: linear-gradient($help, $featured-dark);

// Fixes
$dark1-light: rgba($dark1, 0.2);
$dark2-light: rgba($dark2, 0.2);
$light1: lighten($primary, 42%);
$light2: lighten($primary, 38%);
$light3: lighten($primary, 34%);
$accent1: $dark2;
$primary-alt: $primary;
$primary-alt-light: $primary;
$accent1-light: $dark2-light;
$accent2: $dark1;
$accent2-light: $dark1-light;
$accent-gradient: $primary-gradient;
$primary-gradient-light: $primary-light;
$accent-gradient-light: $primary-light;
$featured1: #ff514b;
$featured2: #ff844b;
$featured3: #ffbb29;
$featured4: #8ae243;
$featured5: #40dfb7;
$featured6: #4bb7ff;
$featured7: #4b66ff;
$featured8: #7b4bff;
$featured9: #48ebc5;

$featured1-light: rgba($featured1, 0.15);
$featured2-light: rgba($featured2, 0.15);
$featured3-light: rgba($featured3, 0.15);
$featured4-light: rgba($featured4, 0.15);
$featured5-light: rgba($featured5, 0.15);
$featured6-light: rgba($featured6, 0.15);
$featured7-light: rgba($featured7, 0.15);
$featured8-light: rgba($featured8, 0.15);
$featured9-light: rgba($featured9, 0.15);
$featured10: #4bdd97;
$featured10-light: rgba($featured10, 0.15);
$featured11: #96e43c;
$featured11-light: rgba($featured11, 0.15);
$featured12: #c3e40d;
$featured12-light: rgba($featured12, 0.15);
$featured13: #e9e900;
$featured13-light: rgba($featured13, 0.15);
$featured14: #ffd22d;
$featured14-light: rgba($featured14, 0.15);
$featured15: rgb(247, 170, 70);
$featured15-light: rgba($featured15, 0.15);
$featured16: rgb(245, 123, 86);
$featured16-light: rgba($featured16, 0.15);
$featured17: hsl(9, 100%, 66%);
$featured17-light: rgba($featured17, 0.15);
$white-light: rgba($white, 0.15);
$featured2-gradient: linear-gradient($featured2-light, $featured2);

$primary2: #33dd90;
$primary2-light: rgba($primary2, 0.15);

$black: #000;

$featured18: rgb(253, 111, 76);
$featured18-light: rgb(254, 230, 205);
$featured18-dark: rgb(253, 95, 10);

$featured19: rgb(253, 90, 95);
$featured19-light: rgb(254, 222, 205);
$featured19-dark: rgb(254, 90, 95);

$featured20: rgb(0, 189, 109);
$featured20-light: rgb(213, 255, 205);

$featured21: rgb(153, 230, 158);
$featured21-light: rgb($featured21, 0.15);
$featured20-gradient-alt: linear-gradient(to right, $featured21, $featured20);
