/*
 * CUSTOM MATERIAL COMPONENTS
 * styles for angular-material custom modified components
 */
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.mat-tooltip{
  background: $dark1;
  &.tooltip-action{
    background: $primary;
    color: $white;
  }
  &.tooltip-warn{
    background: $warn;
    color: $white;
    white-space: pre-line;
  }
  &.tooltip-primary{
    background: $primary;
    color: $white;
  }
  &.tooltip-premium{
    background: $premium;
    color: $white;
  }
  &.tooltip-gray{
    background: $dark2;
    color: $white;
  }
}

.mat-form-field-infix{
  min-width: 60px !important;
  width: 100% !important;
}

.mat-slide-toggle-content, .mat-menu-item{
  font-family: $primary-font;
  color: $dark2;
}

.mat-error{
  font-family: $primary-font;
}

.mat-autocomplete-panel{
  border-radius: 0 0 4px $brad3 !important;
  max-height: 390px !important;
}

.mat-option-text{
  display: flex !important;
  align-items: center;
}

.mat-expansion-panel {
  // box-shadow: $shad2 !important;
  border-radius: 0 !important;
}

.mat-form-field-wrapper {
  width: 100% !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: $brad2 !important;
  background-color: $white;
  border: $btransparent10;
  transition: $animate1;
  &:hover,
  &:focus-within {
    box-shadow: $shad3;
    border: $btransparent;
    transition: $animate1;
  }
}
.mat-form-field.mat-form-field-invalid .mat-form-field-flex {
    border: $bwarn;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field.mat-focused .mat-form-field-label, .mat-primary .mat-option.mat-selected:not(.mat-option-disabled), .mat-select-value {
  color: $dark1;
}

.mat-form-field .mat-form-field-label,
.mat-form-field.mat-focused .mat-input-element, .mat-option, .mat-select-arrow{
  color: $dark2;
}

.mat-input-element {
  color: $dark1;
}

.mat-menu-item .mat-icon {
  vertical-align: baseline;
  margin: 0;
}

.mat-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > * {
  vertical-align: baseline !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: -2px !important;
}

.mat-form-field-appearance-fill .mat-form-field-prefix,
.mat-form-field-appearance-fill .mat-form-field-suffix {
  top: -6px !important;
}

mat-form-field {
  width: 100%;
}

.mat-dialog-title{
  margin: 0 !important;
}

// Viewport Laptop Large (1440px)
@media (min-width: $viewport-laptop-l) {
}
// Viewport 4k (2560px)
@media (min-width: $viewport-4k) {
}
// Viewport Tablet (768px)
@media (max-width: $viewport-tablet) {
}
// Viewport Mobile Large (425px)
@media (max-width: $viewport-mobile-l) {
  .mat-button{
    font-size: $size2;
  }
}
// Viewport Mobile Medium (375px)
@media (max-width: $viewport-mobile-m) {
}
// Viewport Mobile Small (320px)
@media (max-width: $viewport-mobile-s) {
}