@use '@angular/material' as mat;
@import "@angular/material/theming";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

$primary1: (
  50: hsl(164, 46%, 92%),
  100: hsl(163, 46%, 80%),
  200: hsl(164, 45%, 67%),
  300: hsl(164, 45%, 54%),
  400: hsl(165, 57%, 44%),
  500: hsl(166, 79%, 35%),
  600: hsl(165, 80%, 32%),
  700: hsl(150, 51%, 53%),
  800: hsl(164, 98%, 23%),
  900: hsl(160, 100%, 17%),
  A100: hsla(164, 86%, 27%, 0.870),
  A200: hsla(164, 86%, 27%, 0.815),
  A400: hsla(164, 86%, 27%, 0.600),
  A700: hsla(164, 86%, 27%, 0.540),
  contrast: (50: hsl(202, 12%, 44%),
    100: hsl(202, 12%, 44%),
    200: hsl(202, 12%, 44%),
    300: hsl(202, 12%, 44%),
    400: hsl(0, 0%, 100%),
    500: hsl(0, 0%, 100%),
    600: hsl(0, 0%, 100%),
    700: hsl(0, 0%, 100%),
    800: hsl(0, 0%, 100%),
    900: hsl(0, 0%, 100%),
    A100: hsl(202, 12%, 44%),
    A200: hsl(202, 12%, 44%),
    A400: hsl(0, 0%, 100%),
    A700: hsl(0, 0%, 100%))
);



$secondary1: (
  50: hsl(12, 87%, 94%),
  100: hsl(18, 100%, 85%),
  200: hsl(18, 100%, 75%),
  300: hsl(19, 100%, 65%),
  400: hsl(14, 100%, 64%),
  500: hsl(19, 100%, 49%),
  600: hsl(19, 100%, 47%),
  700: hsl(18, 100%, 44%),
  800: hsl(17, 100%, 42%),
  900: hsl(14, 100%, 37%),
  A100: hsla(19, 100%, 65%, 0.870),
  A200: hsla(19, 100%, 65%, 0.815),
  A400: hsla(19, 100%, 65%, 0.600),
  A700: hsla(19, 100%, 65%, 0.540),
  contrast: (50: hsl(202, 12%, 44%),
    100: hsl(202, 12%, 44%),
    200: hsl(202, 12%, 44%),
    300: hsl(202, 12%, 44%),
    400: hsl(0, 0%, 100%),
    500: hsl(0, 0%, 100%),
    600: hsl(0, 0%, 100%),
    700: hsl(0, 0%, 100%),
    800: hsl(0, 0%, 100%),
    900: hsl(0, 0%, 100%),
    A100: hsl(202, 12%, 44%),
    A200: hsl(202, 12%, 44%),
    A400: hsl(0, 0%, 100%),
    A700: hsl(0, 0%, 100%))
);



$warning1: (
  100: hsl(354, 100%, 90%),
  200: hsl(0, 73%, 77%),
  300: hsl(0, 69%, 67%),
  400: hsl(1, 83%, 63%),
  500: hsl(4, 90%, 58%),
  600: hsl(1, 77%, 55%),
  700: hsl(3, 100%, 65%),
  800: hsl(0, 66%, 47%),
  900: hsl(0, 73%, 41%),
  A100: hsl(5, 100%, 75%),
  A200: hsl(0, 100%, 66%),
  A400: hsl(348, 100%, 55%),
  A700: hsl(0, 100%, 42%),
  contrast: (50: hsl(202, 12%, 44%),
    100: hsl(202, 12%, 44%),
    200: hsl(202, 12%, 44%),
    300: hsl(202, 12%, 44%),
    400: hsl(0, 0%, 100%),
    500: hsl(0, 0%, 100%),
    600: hsl(0, 0%, 100%),
    700: hsl(0, 0%, 100%),
    800: hsl(0, 0%, 100%),
    900: hsl(0, 0%, 100%),
    A100: hsl(202, 12%, 44%),
    A200: hsl(202, 12%, 44%),
    A400: hsl(0, 0%, 100%),
    A700: hsl(0, 0%, 100%))
);

$candy-app-primary: mat.define-palette($primary1, 700, 100);
$candy-app-accent: mat.define-palette($secondary1, 400, 100);
$candy-app-warn: mat.define-palette($warning1, 700, 100);
// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($candy-app-theme);

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px !important;
}