// forms framework

.form,
[class*="form-"] {
  display: flex;
}

.form {
  flex-flow: column;
  &-single-data {
    flex: 1 1 auto;
    flex-flow: column;

    mat-checkbox {
      align-self: center;
      padding-top: $x-space3;
      padding: $x-space3 $x-space2;
    }
  }
  &-double-data {
    .form-single-data:first-child {
      margin-right: $x-space5;
    }
  }
  &-triple-data {
    .form-single-data:nth-child(1), .form-single-data:nth-child(2) {
      margin-right: $x-space5;
    }
  }

}

mat-hint{
  color: $dark1;
  font-family: $primary-font;
}

.mat-form-field-hint-spacer{
  display: none !important;
}

input{
  &.uppercase{
    text-transform: uppercase;
  }
}

// Viewport Laptop Large (1440px)
@media (min-width: $viewport-laptop-l) {
}
// Viewport 4k (2560px)
@media (min-width: $viewport-4k) {
}
// Viewport Tablet (768px)
@media (max-width: $viewport-tablet) {
  .form {
    &-double-data, &-triple-data {
      flex-flow: column;
      .form-single-data:nth-child(1), .form-single-data:nth-child(2) {
        margin-right: 0;
      }
    }
  }
}
// Viewport Mobile Large (425px)
@media (max-width: $viewport-mobile-l) {
}
// Viewport Mobile Medium (375px)
@media (max-width: $viewport-mobile-m) {
}
// Viewport Mobile Small (320px)
@media (max-width: $viewport-mobile-s) {
}