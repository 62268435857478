// Buttons Framework

button {
  cursor: pointer;
}

.mat-button {
  border-radius: $brad5 !important;
  font-size: $size3;
  line-height: 32px;

  &.half-rounded {
    border-radius: 0 $brad5 $brad5 0 !important;
  }
}

.mat-button-toggle-group {
  border-radius: $brad5 !important;
  font-size: $size3;
  line-height: 32px;

  &.half-rounded {
    border-radius: 0 $brad5 $brad5 0 !important;
  }
}

.mat-icon-button {
  font-size: $size3;
  line-height: 32px;
  width: 32px !important;
  height: 32px !important;

  .mat-icon {
    width: 24px !important;
    height: 24px !important;
    line-height: 20px !important;
  }

  .material-icons {
    font-size: 24px !important;
  }
}

.primary {
  color: $white;
  background: $primary;

  p {
    color: $white;
  }

  &-alt {
    color: $primary;

    p {
      color: $primary;
    }

    .mat-button-focus-overlay {
      background: $primary;
    }
  }

  &-toggle {
    color: $white;
    background: $primary;

    .mat-button-toggle-appearance-standard {
      color: $white;
      background: $primary;
    }
  }
}

.secondary {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    background: $gray3;
  }

  & i {
    background: $gray2;
  }

  &.active,
  &.router-link-active {
    color: $primary-dark;
    background: $primary-light;

    &:hover,
    &:focus,
    &:focus-within {
      background: $primary-alt;
    }

    & i {
      background: $primary-light;
    }
  }
}

.primary-disabled {
  color: $white;
  background: $primary-light;

  &-alt {
    color: $primary-light;

    .mat-button-focus-overlay {
      background: $primary-light;
    }
  }
}

.primary-gradient {
  color: $white;
  background: $primary-gradient;
}

.primary-gradient-alt {
  color: $white;
  background: $primary-gradient-alt;
}

.accent1 {
  color: $white;
  background: $accent1;

  &-alt {
    color: $accent1;

    .mat-button-focus-overlay {
      background: $accent1;
    }
  }

  &-secundary {
    color: $accent1;
    background: $accent1-light;
  }
}

.accent2 {
  color: $white;
  background: $accent2;

  &-alt {
    color: $accent2;

    .mat-button-focus-overlay {
      background: $accent2-light;
    }
  }
}

.upload {
  color: $white;
  background: $dark3;
  border-radius: $brad2 !important;

  &-alt {
    color: $dark2;

    .mat-button-focus-overlay {
      background: $dark2-light;
    }
  }
}

.accent-gradient {
  color: $white;
  background: $accent-gradient;
}

.transparent {
  color: $white !important;
  background: $transparent20;

  &-alt {
    color: $dark2 !important;
    background: transparent !important;

    p {
      color: $dark2 !important;
    }
  }

  &-warn {
    color: $warn !important;
    background: transparent !important;

    p {
      color: $warn !important;
    }
  }
}

.white {
  color: $white;
  background: $white-light;
}

.dark2 {
  color: $white;
  background: $dark2;

  &-alt {
    color: $dark2;

    .mat-button-focus-overlay {
      background: $dark2;
    }
  }
}

.dark1 {
  color: $white;
  background: $dark1;

  &-alt {
    color: $dark1;

    .mat-button-focus-overlay {
      background: $dark1;
    }
  }
}

.gray2 {
  color: $dark2;
  background: $gray2;

  &-alt {
    color: $gray2;

    .mat-button-focus-overlay {
      background: $gray2;
    }
  }
}

.warn {
  color: $warn;
  background: $warn-light;

  &-gradient {
    color: $white;
    background: $warn-gradient;
  }

  &-alt {
    color: $warn;

    .mat-button-focus-overlay {
      background: $warn;
    }
  }
}

.disabled {
  color: $white;
  background: $gray3;

  &-alt {
    color: $gray3;

    .mat-button-focus-overlay {
      background: $gray3;
    }
  }
}

// featured colors
.featured1 {
  color: $white;
  background: $featured1;

  &-alt {
    color: $featured1;

    .mat-button-focus-overlay {
      background: $featured1;
    }
  }
}

.featured2 {
  color: $white;
  background: $featured2;

  &-alt {
    color: $featured2;

    .mat-button-focus-overlay {
      background: $featured2;
    }
  }
}

.featured3 {
  color: $white;
  background: $featured3;

  &-alt {
    color: $featured3;

    .mat-button-focus-overlay {
      background: $featured3;
    }
  }
}

.featured4 {
  color: $white;
  background: $featured4;

  &-alt {
    color: $featured4;

    .mat-button-focus-overlay {
      background: $featured4;
    }
  }
}

.featured5 {
  color: $white;
  background: $featured5;

  &-alt {
    color: $featured5;

    .mat-button-focus-overlay {
      background: $featured5;
    }
  }
}

.featured6 {
  color: $white;
  background: $featured6;

  &-alt {
    color: $featured6;

    .mat-button-focus-overlay {
      background: $featured6;
    }
  }
}

.featured7 {
  color: $white;
  background: $featured7;

  &-alt {
    color: $featured7;

    .mat-button-focus-overlay {
      background: $featured7;
    }
  }
}

.featured8 {
  color: $white;
  background: $featured8;

  &-alt {
    color: $featured8;

    .mat-button-focus-overlay {
      background: $featured8;
    }
  }
}

.featured9 {
  color: $white;
  background: $featured9;

  &-alt {
    color: $featured9;

    .mat-button-focus-overlay {
      background: $featured9;
    }
  }
}

.featured10 {
  color: $white;
  background: $featured10;

  &-alt {
    color: $featured10;

    .mat-button-focus-overlay {
      background: $featured10;
    }
  }
}

.featured11 {
  color: $white;
  background: $featured11;

  &-alt {
    color: $featured11;

    .mat-button-focus-overlay {
      background: $featured11;
    }
  }
}

.featured12 {
  color: $white;
  background: $featured12;

  &-alt {
    color: $featured12;

    .mat-button-focus-overlay {
      background: $featured12;
    }
  }
}

.featured13 {
  color: $white;
  background: $featured13;

  &-alt {
    color: $featured13;

    .mat-button-focus-overlay {
      background: $featured13;
    }
  }
}

.featured14 {
  color: $white;
  background: $featured14;

  &-alt {
    color: $featured14;

    .mat-button-focus-overlay {
      background: $featured14;
    }
  }
}

.featured15 {
  color: $white;
  background: $featured15;

  &-alt {
    color: $featured15;

    .mat-button-focus-overlay {
      background: $featured15;
    }
  }
}

.featured16 {
  color: $white;
  background: $featured16;

  &-alt {
    color: $featured16;

    .mat-button-focus-overlay {
      background: $featured16;
    }
  }
}

.featured17 {
  color: $white;
  background: $featured17;

  &-alt {
    color: $featured17;

    .mat-button-focus-overlay {
      background: $featured17;
    }
  }
}

// Buttons Framework

$btn-xs: 24px;
$btn-sm: 28px;
$btn-md: 36px;
$btn-lg: 44px;
$btn-xl: 52px;

.btn,
[class*="btn-"] {
  display: flex;
}

.btn {
  cursor: pointer;
  transition: $animate1;
  background: $transparent;
  color: $dark1;
  border: none;
  flex: 0 0 auto;
  padding: 0;
  margin: 0 0 0 4px;
  font-weight: $fw-bold;
  text-transform: inherit;

  &:first-child {
    margin: 0;
  }
}

.btn-xs {
  height: $btn-xs;
  font-size: $fs1;
  border-radius: $brad3;

  &-icon {
    height: $btn-xs;
    width: $btn-xs;
  }

  &-text {
    flex: 0 0 auto;
    height: $btn-xs;
    line-height: $btn-xs;
    padding: 0 $x2;

    &.center {
      padding: 0;
    }

    &.right {
      padding: 0 $x2 0 0;
    }

    &.left {
      padding: 0 0 0 $x2;
    }
  }
}

.btn-sm {
  height: $btn-sm;
  font-size: $fs2;
  border-radius: $brad3;

  &-icon {
    height: $btn-sm;
    width: $btn-sm;
  }

  &-text {
    flex: 0 0 auto;
    height: $btn-sm;
    line-height: $btn-sm;
    padding: 0 $x3;

    &.center {
      padding: 0;
    }

    &.right {
      padding: 0 $x3 0 0;
    }

    &.left {
      padding: 0 0 0 $x3;
    }
  }
}

.btn-md {
  height: $btn-md;
  font-size: $fs3;
  border-radius: $brad-md;

  &-icon {
    height: $btn-md;
    width: $btn-md;
  }

  &-text {
    flex: 0 0 auto;
    height: $btn-md;
    line-height: $btn-md;
    padding: 0 $x3;
    font-family: $primary-font;

    &.center {
      padding: 0;
    }

    &.right {
      padding: 0 $x3 0 0;
    }

    &.left {
      padding: 0 0 0 $x3;
    }
  }
}

.btn-lg {
  height: $btn-lg;
  font-size: $fs5;
  border-radius: $brad5;

  &-icon {
    height: $btn-lg;
    width: $btn-lg;
  }

  &-text {
    flex: 0 0 auto;
    height: $btn-lg;
    line-height: $btn-lg;
    padding: 0 $x3;

    &.center {
      padding: 0;
    }

    &.right {
      padding: 0 $x3 0 0;
    }

    &.left {
      padding: 0 0 0 $x3;
    }
  }
}

.btn-xl {
  height: $btn-xl;
  font-size: $fs7;
  border-radius: $brad5;

  &-icon {
    height: $btn-xl;
    width: $btn-xl;
  }

  &-text {
    flex: 0 0 auto;
    height: $btn-xl;
    line-height: $btn-xl;
    padding: 0 $x3;

    &.center {
      padding: 0;
    }

    &.right {
      padding: 0 $x3 0 0;
    }

    &.left {
      padding: 0 0 0 $x3;
    }
  }
}

.btn-loading {
  min-width: 120px;
  justify-content: center;
}

.btn-container {
  align-items: center;
}

.btn-list {
  display: flex;
  flex-flow: column;
}

.btn-item {
  display: flex;
  border-radius: 6px;

  &:hover,
  &:focus,
  &:focus-within {
    background: $dark-transparent1;
  }

  & .btn {
    flex: 1;
  }
}

// Button Colors
// light
.btn-light {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    background: $gray1;
  }

  & i {
    background: $light-transparent2;
  }

  &.active,
  &.router-link-active {
    color: $primary-dark;
    background: $primary-light;

    &:hover,
    &:focus,
    &:focus-within {
      background: $primary-alt;
    }

    & i {
      background: $primary-light;
    }
  }
}

.btn-light-transparent {
  color: $light-transparent5;
  background: $light-transparent2;

  &:hover,
  &:focus,
  &:focus-within {
    background: $light-transparent1;
  }
}

.btn-light-help {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    background: $help-light;
    color: $help-dark;
  }

  & i {
    background: $light-transparent2;
  }

  &.active,
  &.router-link-active {
    color: $help-dark;
    background: $help-light;

    &:hover,
    &:focus,
    &:focus-within {
      background: $help-alt;
    }

    & i {
      background: $help-light;
    }
  }
}

.btn-light-money {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    background: $money-light;
    color: $money-dark;
  }

  & i {
    background: $light-transparent2;
  }

  &.active,
  &.router-link-active {
    color: $money-dark;
    background: $money-light;

    &:hover,
    &:focus,
    &:focus-within {
      background: $money-alt;
    }

    & i {
      background: $money-light;
    }
  }
}

.btn-light-alert {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    background: $alert-light;
    color: $alert-dark;
  }

  & i {
    background: $light-transparent2;
  }

  &.active,
  &.router-link-active {
    color: $alert-dark;
    background: $alert-light;

    &:hover,
    &:focus,
    &:focus-within {
      background: $alert-alt;
    }

    & i {
      background: $alert-light;
    }
  }
}

.btn-light-info-light {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    background: $info-alt;
    animation: shadow-info 1.2s infinite linear;
    color: $info-dark;
  }

  & i {
    background: $white;
  }
}

.btn-light-primary-light {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    background: $primary-alt;
    animation: shadow-primary 1.2s infinite linear;
    color: $primary-dark;
  }

  & i {
    background: $white;
  }
}

.btn-light-info {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    color: $white;
    background: $info-dark;

    & i {
      background: $info;
    }
  }
}

.btn-light-primary {
  color: $dark1;
  background: $transparent;

  &:hover,
  &:focus,
  &:focus-within {
    color: $white;
    background: $primary-dark;

    & i {
      background: $primary;
    }
  }
}

// primary
.btn-primary {
  color: $white;
  background: $primary;
  box-shadow: 0px 0px 10px $transparent, 0px 0px 30px $transparent;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $primary-dark;
    animation: shadow-primary 1.2s infinite linear;
  }

  & i {
    background: $primary;
  }
}

.btn-primary-light {
  color: $primary-dark;
  background: $primary-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    background: $primary-alt;
  }

  & i {
    background: $primary-light;
  }
}

.btn-primary-a {
  color: $white;
  background: $primary;
  animation: shadow-primary 1.2s infinite linear;
}

// secondary
.btn-secondary {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    background: $gray3;
  }

  & i {
    background: $gray2;
  }

  &.active,
  &.router-link-active {
    color: $primary-dark;
    background: $primary-light;

    &:hover,
    &:focus,
    &:focus-within {
      background: $primary-alt;
    }

    & i {
      background: $primary-light;
    }
  }
}

.btn-secondary-linked {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    color: $white;
    background: $linked-dark;

    & i {
      background: $linked;
    }
  }
}

.btn-secondary-danger {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    color: $white;
    background: $danger-dark;

    & i {
      background: $danger;
    }
  }
}

.btn-secondary-success {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    color: $white;
    background: $success-dark;

    & i {
      background: $success;
    }
  }
}

.btn-secondary-info {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    color: $info-dark;
    background: $info-alt;

    & i {
      background: $info-light;
    }
  }
}

.btn-secondary-link {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    color: $white;
    background: $link-dark;

    & i {
      background: $link;
    }
  }
}

.btn-secondary-social {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    color: $white;
    background: $social-dark;

    & i {
      background: $social;
    }
  }
}

.btn-secondary-alert {
  color: $dark1;
  background: $gray2;

  &:hover,
  &:focus,
  &:focus-within {
    color: $alert-dark;
    background: $alert-alt;

    & i {
      background: $alert-light;
    }
  }
}

.btn-social-light {
  color: $social-dark;
  background: $social-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $social-alt;
  }

  & i {
    background: $social-light;
  }
}

.btn-social-light-social {
  color: $social-dark;
  background: $social-light;

  & i {
    background: $social-light;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    color: $white;
    background: $social-dark;

    & i {
      background: $social;
    }
  }
}

.btn-social-a {
  color: $white;
  background: $social;
  animation: shadow-social 1.2s infinite linear;
}

// money
.btn-money {
  color: $money-dark;
  background: $money-light;

  &:hover,
  &:focus,
  &:focus-within {
    background: $money-alt;
  }

  & i {
    background: $money-light;
  }
}

.btn-money-a {
  color: $white;
  background: $money;
  animation: shadow-money 1.2s infinite linear;
}

// link
.btn-link {
  color: $white;
  background: $link;
  box-shadow: 0px 0px 10px $transparent, 0px 0px 30px $transparent;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $link-dark;
    animation: shadow-link 1.2s infinite linear;
  }

  & i {
    background: $link;
  }
}

.btn-link-light {
  color: $link-dark;
  background: $link-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $link-alt;
  }

  & i {
    background: $link-light;
  }
}

.btn-link-a {
  color: $white;
  background: $link;
  animation: shadow-link 1.2s infinite linear;
}

.btn-danger-light {
  color: $danger-dark;
  background: $danger-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $danger-alt;
  }

  & i {
    background: $danger-light;
  }
}

.btn-danger-a {
  color: $white;
  background: $danger;
  animation: shadow-danger 1.2s infinite linear;
}

// alert
.btn-alert {
  color: $white;
  background: $alert;
  box-shadow: 0px 0px 10px $transparent, 0px 0px 30px $transparent;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $alert-dark;
    animation: shadow-alert 1.2s infinite linear;
  }

  & i {
    background: $alert;
  }
}

.btn-alert-light {
  color: $alert-dark;
  background: $alert-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $alert-alt;
  }

  & i {
    background: $alert-light;
  }
}

.btn-alert-a {
  color: $white;
  background: $alert;
  animation: shadow-alert 1.2s infinite linear;
}

// warn
.btn-warn {
  color: $white;
  background: $warn;
  box-shadow: 0px 0px 10px $transparent, 0px 0px 30px $transparent;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $warn-dark;
    animation: shadow-warn 1.2s infinite linear;
  }

  & i {
    background: $warn;
  }
}

.btn-warn-light {
  color: $warn-dark;
  background: $warn-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $warn-alt;
  }

  & i {
    background: $warn-light;
  }
}

.btn-warn-a {
  color: $white;
  background: $warn;
  animation: shadow-warn 1.2s infinite linear;
}

// info
.btn-info {
  color: $white;
  background: $info;
  box-shadow: 0px 0px 10px $transparent, 0px 0px 30px $transparent;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $info-dark;
    animation: shadow-info 1.2s infinite linear;
  }

  & i {
    background: $info;
  }
}

.btn-info-light {
  color: $info-dark;
  background: $info-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $info-alt;
  }

  & i {
    background: $info-light;
  }
}

.btn-info-a {
  color: $white;
  background: $info;
  animation: shadow-info 1.2s infinite linear;
}

// success
.btn-success {
  color: $white;
  background: $success;
  box-shadow: 0px 0px 10px $transparent, 0px 0px 30px $transparent;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $success-dark;
    animation: shadow-success 1.2s infinite linear;
  }

  & i {
    background: $success;
  }
}

.btn-success-light {
  color: $success-dark;
  background: $success-light;

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    transition: $animate1;
    background: $success-alt;
  }

  & i {
    background: $success-light;
  }
}

.btn-success-a {
  color: $white;
  background: $success;
  animation: shadow-success 1.2s infinite linear;
}

// help
.btn-help {
  color: $white;
  background: $help;

  &:hover,
  &:focus,
  &:focus-within {
    background: $help-dark;
  }

  & i {
    background: $help;
  }
}

.btn-linked-light-linked {
  color: $linked-dark;
  background: $linked-light;

  & i {
    background: $linked-light;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &.active,
  &.router-link-active {
    color: $white;
    background: $linked-dark;

    & i {
      background: $linked;
    }
  }
}

.btn-linked-a {
  color: $white;
  background: $linked;
  animation: shadow-linked 1.2s infinite linear;
}

.btn-disabled {
  cursor: not-allowed !important;
  color: $gray5;
  background: $gray4;

  & i {
    background: $gray4;
  }
}

// Display 4k(2560px or above)
@media (max-width: $display-xl) {}

// Display FullHD (1920px or above)
@media (max-width: $display-lg) {}

// Display Tablet Landscape (1279px or below)
@media (max-width: $display-md) {}

// Display Tablet Portrait (1023px or below)
@media (max-width: $display-sm) {}

// Display Mobile (767px or below)
@media (max-width: $display-xs) {}