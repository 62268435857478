// Text Framework

$accent-font: "Source Sans Pro", sans-serif;
$primary-font: "Lexend Deca", sans-serif;
$secundary-font: "Lexend Deca", sans-serif;
$marketplace-font : var(--mdc-dialog-supporting-text-font, Roboto, sans-serif);

$fw-regular: 400;
$fw-bold: 700;

$size1: 10px;
$size2: 12px;
$size3: 14px;
$size4: 16px;
$size5: 18px;
$size6: 20px;
$size7: 24px;
$size8: 28px;
$size9: 32px;
$size10: 36px;

$spacetitle: -1px;

h1 {
    font-family: $accent-font;
    font-weight: 300;
    margin: 0;
}
h2,
h3,
h4,
h5,
h6,
p,
select,
span {
    font-family: $primary-font;
    margin: 0;
}
strong {
    font-family: inherit;
}

.data {
    &-container {
        margin: 0;
        overflow: hidden;
        flex: 1;

        &.text  {
            flex-flow: row;
            display: flex;
            flex: 0 1 auto;
        }

        &.left {
            margin: 0 $x2 0 0;
        }

        &.center {
            margin: 0 $x2;
        }

        &.right {
            margin: 0 0 0 $x2;
        }

        & p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &-title {
        font-weight: $bold;
        text-transform: none;
        font-size: $fs3;

        & i {
            font-size: $fs2;
        }
    }

    &-text {
        font-size: $fs2;
        font-weight: $regular;
        color: $dark1;
        text-transform: none;

        & i {
            font-size: $fs1;
        }
    }
}

