// Loaders Framework

.loader,
[class*="loader-"] {
  display: flex;
}

@keyframes loader1 {
  0% {
    color: $dark1;
  }

  50% {
    color: $primary;
  }

  100% {
    color: $dark1;
  }
}

.loader {
  align-items: flex-end;
  i {
    color: $dark1;
    margin: $y-space1 $x-space1;
    font-size: $size8;
    animation: loader1 1.2s infinite ease-in-out;
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 0.6s infinite linear;
}
