// Measures Framework
$x05: 2px;
$x1: 4px;
$x2: 8px;
$x3: 12px;
$x4: 16px;
$x5: 20px;
$x6: 24px;
$x7: 28px;
$x8: 32px;
$x9: 36px;
$x10: 40px;

$y05: 2px;
$y1: 4px;
$y2: 8px;
$y3: 12px;
$y4: 16px;
$y5: 20px;
$y6: 24px;
$y7: 28px;
$y8: 32px;
$y9: 36px;
$y10: 40px;

$-x1: 4px;
$-x2: 8px;
$-x3: 12px;
$-x4: 16px;
$-x5: 20px;
$-x6: 24px;
$-x7: 28px;
$-x8: 32px;
$-x9: 36px;
$-x10: 40px;

$-y1: 4px;
$-y2: 8px;
$-y3: 12px;
$-y4: 16px;
$-y5: 20px;
$-y6: 24px;
$-y7: 28px;
$-y8: 32px;
$-y9: 36px;
$-y10: 40px;

$height1: 20px;
$height2: 28px;
$height3: 36px;
$height4: 44px;
$height5: 52px;
$height6: 60px;
$height7: 72px;
$height8: 84px;
$height9: 96px;
$height10: 120px;

$width1: 20px;
$width2: 28px;
$width3: 36px;
$width4: 44px;
$width5: 52px;
$width6: 60px;
$width7: 72px;
$width8: 84px;
$width9: 96px;
$width10: 120px;

$brad1: 6px;
$brad2: 8px;
$brad3: 10px;
$brad4: 12px;
$brad5: 16px;
$brad6: 20px;
$brad7: 40px;
$brad8: 60px;
$brad9: 80px;
$brad10: 100px;

// For inputs, buttons and badges

$brad-xs: 6px;
$brad-sm: 8px;
$brad-md: 10px;
$brad-lg: 11px;
$brad-xl: 12px;