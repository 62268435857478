// Animations Framework

$animate1: all 300ms ease-in-out;
$animate2: all 200ms ease-in-out;
$animate3: all 100ms ease-in-out;

a,
a:hover,
button,
button:hover {
    transition: $animate1;
}

@keyframes loading-content {
    0% {
      background:$gray2;
    }
    50% {
      background:$gray3;
    }
    100% {
      background:$gray2;
    }
  }

.loading-content {
    animation: loading-content 1.2s infinite linear;
    width: 100%;
    height: 20px;
    border-radius: $brad1;
}

$animation-time: all 0.2s ease-in-out;