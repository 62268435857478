

.dialog-form,
[class*="dialog-form-"] {
  display: flex;
}

// Dialog sizes
.dialog-view {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  &-xs {
    width: 400px;
  }
  &-sm {
    width: 600px;
  }
  &-md {
    width: 800px;
  }
  &-lg {
    width: 1200px;
  }
  &-xl {
    width: calc(100vw - 40px);
  }
}

.dialog-form {
  flex-flow: column;
  &-header {
    padding-bottom: $y-space2;
    flex: 1;
    h1 {
      color: $dark2;
      font-family: $primary-font;
      font-size: $size7;
      flex: 1;
    }

    &-close {
      align-self: center;
      justify-self: flex-end;
      margin-right: 0;
      display: flex;

    }
  }
  &-body {
    flex-flow: column;
    background: $white;
    padding: $y-space3 $x-space5 !important;
    &-card {
      padding: $y-space3 $x-space5;
      margin-bottom: $y-space2;
      flex: 1 1 auto;
      flex-flow: column;
      font-size: $size3;
      border-radius: $brad2;
      background: $white;
      color: $dark2;
      transition: $animate1;
      h1 {
        font-size: $size6;
        margin-bottom: $y-space2;
      }
      h2 {
        font-size: $size3;
        margin-bottom: $y-space1;
      }
      p {
        color: $dark1;
        margin-bottom: $y-space1;
      }
      &-empty-state {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: $y-space2 0;
        button,
        p {
          margin-top: $y-space2;
        }
      }
    }
  }
  &-single-data {
    flex: 1 1 auto;
    margin: 0 0 $y-space2 0;
    a {
      padding: $y-space3 $x-space5;
      flex: 1 1 auto;
      flex-flow: column;
      font-size: $size3;
      border-radius: $brad2;
      background: $white;
      color: $dark2;
      transition: $animate1;
      h1 {
        font-size: $size5;
        margin-bottom: $y-space1;
      }
      p {
        font-size: $size2;
        color: $dark1;
      }
      &:hover {
        color: $primary;
        box-shadow: $shad2;
        transition: $animate1;
      }
    }
  }
  &-double-data {
    .dialog-form-single-data:first-child {
      margin-right: $x-space5;
    }
  }
  &-triple-data {
    .dialog-form-single-data:nth-child(1),
    .dialog-form-single-data:nth-child(2) {
      margin-right: $x-space5;
    }
  }
  &-summary {
    flex-flow: column;
    margin: 0 0 $y-space2 0;
  }
  &-product {
    align-items: center;
    padding: $y-space2 $x-space4;
    flex: 1 1 auto;
    font-size: $size3;
    border-radius: $brad2;
    background: $gray1;
    color: $dark1;
    margin: 0 0 $y-space2 0;
    p {
      margin: 0;
    }
    &:hover {
      background: $light1;
      color: $primary;
    }
    &-img {
      flex: 0 0 auto;
      width: 50px;
      height: 50px;
      background: $dark3;
      background-size: cover;
      border-radius: $brad3;
      box-shadow: 0px 0px 0px 4px $transparent5;
    }
    &-info {
      flex-flow: column;
      margin-left: $x-space5;
      flex: 1 1 auto;
    }
    &-price {
      flex-flow: column;
      margin-left: $x-space5;
      width: 30%;
    }
  }
  &-footer {
    justify-content: flex-end;
    align-items: center;
  }
  &-checkbox-area {
    align-items: center;
    padding: $y-space2 $x-space4;
    flex: 1 1 auto;
    font-size: $size3;
    border-radius: $brad2;
    background: $gray1;
    color: $dark1;
    margin: 0 0 $y-space2 0;
  }
}

.cdk-overlay-container {
  z-index: 999999;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  backdrop-filter: blur(20px) saturate(200%);
  background: rgba(98, 115, 125, 0.5);
  -webkit-backdrop-filter: blur(20px) saturate(200%);
}

.mat-dialog-container {
  border-radius: $DialogBorderRadius !important;
  overflow: hidden;
}
.bread-crumb {
  position: absolute;
  height: 1px;
  &-dialog {
    position: absolute;
    left: -30px;
    app-breadcrumb-dialog {
      position: absolute;
      top: -66px;
    }
  }
}


// Viewport Laptop Large (1440px)
@media (min-width: $viewport-laptop-l) {
}
// Viewport 4k (2560px)
@media (min-width: $viewport-4k) {
}
// Viewport Tablet (768px)
@media (max-width: $viewport-tablet) {
  .dialog-form {
    &-double-data,
    &-triple-data {
      flex-flow: column;
      .dialog-form-single-data:first-child {
        margin-right: 0;
      }
    }
  }
}
// Viewport Mobile Large (425px)
@media (max-width: $viewport-mobile-l) {
}
// Viewport Mobile Medium (375px)
@media (max-width: $viewport-mobile-m) {
}
// Viewport Mobile Small (320px)
@media (max-width: $viewport-mobile-s) {
}












