// Icons Framework

$icon-xs: 11px;
$icon-sm: 12px;
$icon-md: 13px;
$icon-lg: 14px;
$icon-xl: 16px;
$icon-xxl: 18px;

$icon-bg-xs: 16px;
$icon-bg-sm: 20px;
$icon-bg-md: 24px;
$icon-bg-lg: 26px;
$icon-bg-xl: 30px;
$icon-bg-xxl: 40px;

$icon-brad-xs: 6px;
$icon-brad-sm: 7px;
$icon-brad-md: 8px;
$icon-brad-lg: 9px;
$icon-brad-xl: 10px;
$icon-brad-xxl: 12px;

.fa,
.fad {
    font-size: $size3;
}

.icon-featured {
    text-align: center;
    height: 72px;
    width: 72px;
    line-height: 72px;
    font-size: $size8;
    border-radius: $brad5;

    &.mini {
        height: 52px;
        width: 52px;
        line-height: 52px;
        font-size: $size6;
    }
}

.icon {
    &-xs {
        margin: auto;
        text-align: center;
        height: $icon-bg-xs;
        width: $icon-bg-xs;
        line-height: $icon-bg-xs;
        border-radius: $icon-brad-xs;
        font-size: $icon-xs;
    }

    &-sm {
        margin: auto;
        text-align: center;
        height: $icon-bg-sm;
        width: $icon-bg-sm;
        line-height: $icon-bg-sm;
        border-radius: $icon-brad-sm;
        font-size: $icon-sm;
    }

    &-md {
        margin: auto;
        text-align: center;
        height: $icon-bg-md;
        width: $icon-bg-md;
        line-height: $icon-bg-md;
        border-radius: $icon-brad-md;
        font-size: $icon-md;
    }

    &-lg {
        margin: auto;
        text-align: center;
        height: $icon-bg-lg;
        width: $icon-bg-lg;
        line-height: $icon-bg-lg;
        border-radius: $icon-brad-lg;
        font-size: $icon-lg;
    }

    &-xl {
        margin: auto;
        text-align: center;
        height: $icon-bg-xl;
        width: $icon-bg-xl;
        line-height: $icon-bg-xl;
        border-radius: $icon-brad-xl;
        font-size: $icon-xl;
    }
}


.icon {
    &-toggle {
        cursor: pointer;
    }

    &-primary {
        color: $primary;

        &.icon-featured {
            background: $primary-light;
        }
    }

    &-accent1 {
        color: $accent1;

        &.icon-featured {
            background: $accent1-light;
        }
    }

    &-accent2 {
        color: $accent2;

        &.icon-featured {
            background: $accent2-light;
        }
    }

    &-dark1 {
        color: $dark1;

        &.icon-featured {
            background: $dark1-light;
        }
    }

    &-dark2 {
        color: $dark2;

        &.icon-featured {
            background: $dark2-light;
        }
    }

    &-white {
        color: $white;

        &.icon-featured {
            background: $white-light;
        }
    }

    &-warn {
        color: $warn;

        &.icon-featured {
            background: $warn-light;
        }
    }

    &-featured1 {
        color: $featured1;

        &.icon-featured {
            background: $featured1-light;
        }
    }

    &-featured2 {
        color: $featured2;

        &.icon-featured {
            background: $featured2-light;
        }
    }

    &-featured3 {
        color: $featured3;

        &.icon-featured {
            background: $featured3-light;
        }
    }

    &-featured4 {
        color: $featured4;

        &.icon-featured {
            background: $featured4-light;
        }
    }

    &-featured5 {
        color: $featured5;

        &.icon-featured {
            background: $featured5-light;
        }
    }

    &-featured6 {
        color: $featured6;

        &.icon-featured {
            background: $featured6-light;
        }
    }

    &-featured7 {
        color: $featured7;

        &.icon-featured {
            background: $featured7-light;
        }
    }

    &-featured8 {
        color: $featured8;

        &.icon-featured {
            background: $featured8-light;
        }
    }

    &-featured9 {
        color: $featured9;

        &.icon-featured {
            background: $featured9-light;
        }
    }

    &-featured10 {
        color: $featured10;

        &.icon-featured {
            background: $featured10-light;
        }
    }

    &-featured11 {
        color: $featured11;

        &.icon-featured {
            background: $featured11-light;
        }
    }

    &-featured12 {
        color: $featured12;

        &.icon-featured {
            background: $featured12-light;
        }
    }

    &-featured13 {
        color: $featured13;

        &.icon-featured {
            background: $featured13-light;
        }
    }

    &-featured14 {
        color: $featured14;

        &.icon-featured {
            background: $featured14-light;
        }
    }

    &-featured15 {
        color: $featured15;

        &.icon-featured {
            background: $featured15-light;
        }
    }

    &-featured16 {
        color: $featured16;

        &.icon-featured {
            background: $featured16-light;
        }
    }

    &-featured17 {
        color: $featured17;

        &.icon-featured {
            background: $featured17-light;
        }
    }
}

// Viewport Laptop Large (1440px)
@media (min-width: $viewport-laptop-l) {}

// Viewport 4k (2560px)
@media (min-width: $viewport-4k) {}

// Viewport Tablet (768px)
@media (max-width: $viewport-tablet) {}

// Viewport Mobile Large (425px)
@media (max-width: $viewport-mobile-l) {
    .icon-featured {
        height: 60px;
        width: 60px;
        line-height: 60px;
        font-size: $size6;
    }
}

// Viewport Mobile Medium (375px)
@media (max-width: $viewport-mobile-m) {}

// Viewport Mobile Small (320px)
@media (max-width: $viewport-mobile-s) {}