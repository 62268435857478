// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Agricompra-backoffice-primary: mat.define-palette(mat.$indigo-palette);
$Agricompra-backoffice-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Agricompra-backoffice-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Agricompra-backoffice-theme: mat.define-light-theme((color: (primary: $Agricompra-backoffice-primary,
        accent: $Agricompra-backoffice-accent,
        warn: $Agricompra-backoffice-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($Agricompra-backoffice-theme);

/* You can add global styles to this file, and also import other style files */
/*
 * TRUMPS
 * Overrides, helpers, utilities and shame. The highest specificity
 * and/or classes/properties that must always 'win', using the cascade
 * to beat earlier properties.
 */
@import "./assets/sass/trumps/all";
@import "./assets/sass/material-components/all.scss";
/* Add application styles & imports to this file! */
// scroll
// ::-webkit-scrollbar {
//   width: 4px;
// }
// ::-webkit-scrollbar-track {
//   background: $gray3;
// }
// ::-webkit-scrollbar-thumb {
//   background: $dark2;
// }

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  box-sizing: border-box;
  min-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  font-family: $primary-font;
  font-size: $size3;
  background-color: $gray2;
  margin: 0;
  color: $dark2;
  line-height: 1.2;
}

/* remove yellow color of fields with value */
*:focus {
  outline: none;
}

table {
  border-spacing: 0;
}

a,
a:hover {
  cursor: pointer;
  text-decoration: none;
  transition: $animate3;
}

hr {
  border-top: $btransparent10;
  border-bottom: 0;
  width: 100%;
  margin: $y-space2 0;

  &.divider {
    height: 4px;
    margin: 0;
    border: none;
  }
}

.container {
  display: flex;
  margin: 0 auto;
  width: calc(100% - 30px);
  max-width: $viewport-hd;

  &.condensed {
    width: 80%;
  }
}

.MXN {
  color: #0c9450;
  text-transform: uppercase;
}

.btn:focus-within,
.input:focus-within {
  outline: 2px solid $primary;
}




// Viewport Laptop Large (1440px)
@media (min-width: $viewport-laptop-l) {}

// Viewport 4k (2560px)
@media (min-width: $viewport-4k) {}

// Viewport Tablet (768px)
@media (max-width: $viewport-tablet) {
  .container {
    width: calc(100% - 40px);

    &.condensed {
      width: calc(100% - 40px);
    }

    &.full {
      width: 100%;
    }
  }
}

// Viewport Mobile Large (425px)
@media (max-width: $viewport-mobile-l) {}

// Viewport Mobile Medium (375px)
@media (max-width: $viewport-mobile-m) {}

// Viewport Mobile Small (320px)
@media (max-width: $viewport-mobile-s) {}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}