$fs05: 10px;
$fs1: 11px;
$fs2: 12px;
$fs3: 13px;
$fs4: 14px;
$fs5: 16px;
$fs6: 18px;
$fs7: 20px;
$fs8: 24px;
$fs9: 28px;
$fs10: 32px;
$fs11: 36px;
$fs12: 40px;
$fs13: 44px;
$fs14: 60px;
$fs15: 72px;
$fs16: 80px;

$dark-transparent1: rgba($dark4, 0.05);
$light-transparent1: rgba($white, 0.05);
$light-transparent2: rgba($white, 0.2);
$light-transparent5: rgba($white, 0.85);

// Colors Framework
// Generals
$white: #fff;

// Primary Green (Primary Actions)
$primary: #02cd6e;
$primary-alt: #9cefa8;
$primary-light: #d6ffcb;
$primary-dark: #009c64;
$primary-gradient: linear-gradient(to bottom left, $primary 0%, $primary-alt 100%);

// Success Lime (Success Status)
$success: #84cc16;
$success-alt: #d9f99d;
$success-light: #ecfccb;
$success-dark: #65a30d;
$success-gradient: linear-gradient(to bottom left, $success 0%, $success-alt 100%);

// Money Emerald (Offers & Discounts)
$money: #10b981;
$money-alt: #a7f3d0;
$money-light: #d1fae5;
$money-dark: #059669;
$money-gradient: linear-gradient(to bottom left, $money 0%, $money-alt 100%);

// Help Pink (Help Actions)
$help: #fb7185;
$help-alt: #fecdd3;
$help-light: #ffe4e6;
$help-dark: #f43f5e;
$help-gradient: linear-gradient(to bottom left, $help-dark 0%, $help-alt 100%);

// Link Cyan (Links)
$link: #22d3ee;
$link-alt: #a5f3fc;
$link-light: #cffafe;
$link-dark: #06b6d4;
$link-gradient: linear-gradient(to bottom left, $link 0%, $link-alt 100%);

// Info Sky (Info)
$info: #38bdf8;
$info-alt: #bae6fd;
$info-light: #e0f2fe;
$info-dark: #0ea5e9;
$info-gradient: linear-gradient(to bottom left, $info 0%, $info-alt 100%);

// Social Blue (Social actions)
$social: #3b82f6;
$social-alt: #bfdbfe;
$social-light: #dbeafe;
$social-dark: #2563eb;
$social-gradient: linear-gradient(to bottom left, $social 0%, $social-alt 100%);

// Linked Indigo (Linking Actions)
$linked: #6366f1;
$linked-alt: #c7d2fe;
$linked-light: #e0e7ff;
$linked-dark: #4f46e5;
$linked-gradient: linear-gradient(to bottom left, $linked 0%, $linked-alt 100%);

// Alert Yellow (Alert Actions)
$alert: #ffaf56;
$alert-alt: #ffde97;
$alert-light: #fff6cb;
$alert-dark: #e18a46;
$alert-gradient: linear-gradient(to bottom left, $alert 0%, $alert-alt 100%);

// Warning Orange (Warning & Pending Actions)
$warn: #ff7a59;
$warn-alt: #ffbc99;
$warn-light: #ffe6cc;
$warn-dark: #e85f47;
$warn-gradient: linear-gradient(to bottom left, $warn 0%, $warn-alt 100%);

// Danger Red (Deleting and Negative Actions)
$danger: #ff5f5f;
$danger-alt: #ffac9a;
$danger-light: #ffdecc;
$danger-dark: #ea4d4d;
$danger-gradient: linear-gradient(to bottom left, $danger 0%, $danger-alt 100%);

// Light Gray (Light Contrast & Backgrounds)
$gray1: #f8fafc;
$gray2: #f1f5f9;
$gray3: #e2e8f0;
$gray4: #cbd5e1;
$gray5: #94a3b8;

// Dark Gray (Dark Contrast & Backgrounds)
$dark1: #64748b;
$dark2: #475569;
$dark3: #334155;
$dark4: #1e293b;
$dark5: #0f172a;

// MXN Green (MXN Currency)
$mxn: #0c9450;
$mxn-light: #dbffed;
$mxn-alt: #ccfae3;
$mxn-dark: #04763b;
// USD Blue (USD Currency)
$usd: #228ce2;
$usd-light: #d1eaff;
$usd-alt: #9ed3ff;
$usd-dark: #004e8a;

//Transparents
$transparent: transparent;
$dark-transparent1: rgba($dark4, 0.05);
$dark-transparent2: rgba($dark4, 0.2);
$dark-transparent3: rgba($dark4, 0.5);
$dark-transparent4: rgba($dark4, 0.7);
$dark-transparent5: rgba($dark4, 0.85);
$light-transparent1: rgba($white, 0.05);
$light-transparent2: rgba($white, 0.2);
$light-transparent3: rgba($white, 0.5);
$light-transparent4: rgba($white, 0.7);
$light-transparent5: rgba($white, 0.85);

// Customs
$overlay: rgba($gray3, 0.85);
$disabled: $gray4;
$disabled-light: $gray3;
$empty: $gray5;
$border: solid 1px $gray3;
// Displays framework

$display-xs: 767px;
$display-sm: 1023px;
$display-md: 1279px;
$display-lg: 1920px;
$display-xl: 2560px;

$partial: #fcbd78;

.title-partial {
  color: $partial;
}