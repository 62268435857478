/*
  border HELPER
*/
$brad1: 4px;
$brad2: 8px;
$brad3: 16px;
$brad4: 32px;
$brad5: 64px;
$leaf1: 30px 5px;

$btransparent: solid 1px $transparent;
$btransparent5: solid 1px $transparent5;
$btransparent10: solid 1px $transparent10;
$btransparent20: solid 1px $transparent20;
$b10black10: solid 10px $transparent10;
$bwarn: solid 1px $warn;
// Dialogs SCSS
$DialogBorderRadius: 24px;