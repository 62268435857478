/*
  Display viewports framework
*/

.display {
  &-grid {
    display: grid;
  }

  &-flex {
    display: flex;
  }

  &-contents {
    display: contents;
  }
}

$viewport-hd: 1920px;

$viewport-mobile-s: 320px;
$viewport-mobile-m: 375px;
$viewport-mobile-l: 425px;
$viewport-tablet: 768px;
$viewport-laptop: 1024px;
$viewport-chat: 1200px;
$viewport-laptop-l: 1440px;
$viewport-4k: 2560px;

// Viewport Laptop Large (1440px)
@media (min-width: $viewport-laptop-l) {}

// Viewport 4k (2560px)
@media (min-width: $viewport-4k) {}

// Viewport Tablet (768px)
@media (max-width: $viewport-tablet) {}

// Viewport Mobile Large (425px)
@media (max-width: $viewport-mobile-l) {}

// Viewport Mobile Medium (375px)
@media (max-width: $viewport-mobile-m) {}

// Viewport Mobile Small (320px)
@media (max-width: $viewport-mobile-s) {}