.pager,
[class*="pager-"] {
  display: flex;
}

.pager{
    flex: 1 1 auto;
    flex-flow: column;
    overflow: hidden;
    &-container{
        justify-content: center;
    }
    &-item{
        button{
            margin: 0 $x-space1;
            padding: 0 $x-space2;
            font-size: $size2;
            min-width: 0;
            &.active{
                color: $primary;
                background: $primary-light;
            }
        }
    }
}

// Viewport Laptop Large (1440px)
@media (min-width: $viewport-laptop-l) {
}
// Viewport 4k (2560px)
@media (min-width: $viewport-4k) {
}
// Viewport Tablet (768px)
@media (max-width: $viewport-tablet) {
}
// Viewport Mobile Large (425px)
@media (max-width: $viewport-mobile-l) {
    .pager{
        &-item{
            button{
                &.page-number{
                    display: none;
                    &.active{
                        display: flex;
                    }
                }
            }
        }
    }
}
// Viewport Mobile Medium (375px)
@media (max-width: $viewport-mobile-m) {
}
// Viewport Mobile Small (320px)
@media (max-width: $viewport-mobile-s) {
}
