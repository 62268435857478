// Assistant Framework

.assistant-tutorial,
[class*="assistant-tutorial-"] {
  display: flex;
}

.assistant-tutorial {
  align-items: center;
  padding: $y3 0;
  flex: 1;

  &.active {
    & .assistant-tutorial-icon {
      color: $white;
      background: $info;
      animation: talk 0.4s infinite linear, shadow-info 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $info-dark;
    }
  }

  &.primary {

    & .assistant-tutorial-icon {
      color: $white;
      background: $primary;
      animation: talk 0.4s infinite linear, shadow-primary 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $primary-dark;
    }
  }

  &.info {

    & .assistant-tutorial-icon {
      color: $white;
      background: $info;
      animation: talk 0.4s infinite linear, shadow-info 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $info-dark;
    }
  }

  &.alert {

    & .assistant-tutorial-icon {
      color: $white;
      background: $alert;
      animation: talk 0.4s infinite linear, shadow-alert 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $alert-dark;
    }
  }

  &.danger {

    & .assistant-tutorial-icon {
      color: $white;
      background: $danger;
      animation: talk 0.4s infinite linear, shadow-danger 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $danger-dark;
    }
  }

  &.warn {

    & .assistant-tutorial-icon {
      color: $white;
      background: $warn;
      animation: talk 0.4s infinite linear, shadow-warn 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $warn-dark;
    }
  }

  &.success {

    & .assistant-tutorial-icon {
      color: $white;
      background: $success;
      animation: talk 0.4s infinite linear, shadow-success 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $success-dark;
    }
  }

  &.money {

    & .assistant-tutorial-icon {
      color: $white;
      background: $money;
      animation: talk 0.4s infinite linear, shadow-money 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $money-dark;
    }
  }

  &.link {

    & .assistant-tutorial-icon {
      color: $white;
      background: $link;
      animation: talk 0.4s infinite linear, shadow-link 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $link-dark;
    }
  }

  &.linked {

    & .assistant-tutorial-icon {
      color: $white;
      background: $linked;
      animation: talk 0.4s infinite linear, shadow-linked 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $linked-dark;
    }
  }

  &.social {

    & .assistant-tutorial-icon {
      color: $white;
      background: $social;
      animation: talk 0.4s infinite linear, shadow-social 1.2s infinite linear;
    }

    & .assistant-tutorial-text p {
      color: $social-dark;
    }
  }

  &-icon {
    color: $gray5;
    font-size: $fs8;
    margin-right: $x5;
    background: $disabled;
    border-radius: $brad3;
    height: $height4;
    aspect-ratio: 4 / 3;
    align-items: center;
    justify-content: center;
  }

  &-text {
    flex: 1;
    flex-flow: column;

    &.white p{
      color: $white;
    }

    & p {
      color: $dark1;
      line-height: 1.5;
    }
  }
}

.assistant-comment {
  font-family: monospace;
  font-size: $fs4;
  line-height: $fs6;
}

// Display 4k(2560px or above)
@media (max-width: $display-xl) {
}

// Display FullHD (1920px or above)
@media (max-width: $display-lg) {
}

// Display Desktop

// Display Tablet Landscape (1119px or below)
@media (max-width: $display-md) {
}

// Display Tablet Portrait (1023px or below)
@media (max-width: $display-sm) {
    .assistant-tutorial{
      flex-flow: column;
      &-icon {
        margin-right: 0;
        margin-bottom: $y5;
      }
      &-text {
        text-align: center;
      }
    }
}

// Display Mobile (767px or below)
@media (max-width: $display-xs) {
}