// Agricompra Framework 1.0 - Spacing

$y-space1: 6px;
$y-space2: 12px;
$y-space3: 18px;
$y-space4: 24px;
$y-space5: 30px;
$y-space6: 60px;
$y-space7: 120px;
$y-space8: 180px;
$y-space9: 240px;
$y-space10: 300px;

$x-space1: 4px;
$x-space2: 8px;
$x-space3: 12px;
$x-space4: 16px;
$x-space5: 20px;
$x-space6: 24px;
$x-space7: 28px;
$x-space8: 32px;
$x-space9: 36px;
$x-space10: 40px;

//x space right
.xr1 {
    margin-right: $x-space1;
}
.xr2 {
    margin-right: $x-space2;
}
.xr3 {
    margin-right: $x-space3;
}
.xr4 {
    margin-right: $x-space4;
}
.xr5 {
    margin-right: $x-space5;
}
.xr6 {
    margin-right: $x-space6;
}
.xr7 {
    margin-right: $x-space7;
}
.xr8 {
    margin-right: $x-space8;
}
.xr9 {
    margin-right: $x-space9;
}
.xr10 {
    margin-right: $x-space10;
}


//Negative X values
$negative-x1: -4px;
$negative-x2: -8px;
$negative-x3: -12px;
$negative-x4: -16px;
$negative-x5: -20px;
$negative-x6: -24px;
$negative-x7: -28px;
$negative-x8: -32px;
$negative-x9: -36px;
$negative-x10: -40px;
$negative-x11: -44px;
$negative-x12: -48px;
$negative-x13: -52px;
$negative-x14: -58px;

//Negative Y values
$negative-y1: -6px;
$negative-y2: -12px;
$negative-y3: -18px;
$negative-y4: -24px;
$negative-y5: -30px;
$negative-y6: -60px;
$negative-y7: -120px;
$negative-y8: -180px;
$negative-y9: -240px;
$negative-y10: -300px;
