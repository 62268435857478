.input {
    &-dark1 {
        ::ng-deep .mat-focused .mat-form-field-label {
            /*change color of label*/
            color: $dark2 !important;
        }

        ::ng-deep.mat-form-field-underline {
            /*change color of underline*/
            background-color: $gray2 !important;
        }

        ::ng-deep.mat-form-field-ripple {
            /*change color of underline when focused*/
            background-color: $dark1 !important;
        }

        ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: $gray2 !important;
            color: $dark2 !important;
        }
    }

    &-primary {
        ::ng-deep .mat-focused .mat-form-field-label {
            /*change color of label*/
            color: $primary !important;
        }

        ::ng-deep.mat-form-field-underline {
            /*change color of underline*/
            background-color: $primary-light !important;
        }

        ::ng-deep.mat-form-field-ripple {
            /*change color of underline when focused*/
            background-color: $primary-light !important;
        }

        ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: $primary-light !important;
            color: $primary !important;
        }
    }

    &-size1 {
        mat-form-field.mat-form-field {
            font-size: $size1 !important;
        }

        ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
            border-radius: $brad5 !important;
        }
    }

    &-size2 {
        width: 65%;
        margin-right: 0px;

        mat-form-field.mat-form-field {
            font-size: $size1 !important;
        }

        ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
            border-radius: $brad5 !important;
        }
    }
}

// Inputs Framework

// Generals
$input-xs: 24px;
$input-sm: 28px;
$input-md: 32px;
$input-lg: 36px;
$input-xl: 40px;

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $gray5;
    opacity: 0.6;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray5;
    opacity: 0.6;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray5;
    opacity: 0.6;
}

.inputs-row {
    display: flex;
    flex: 1;
}

.input-container {
    display: flex;
    flex: 1;
    flex-flow: column;
    margin-left: $x2;
    gap: 8px;

    & .input {
        margin: 0;
    }

    &.md {
        flex: 0 0 20%;
    }

    &.xs {
        flex: 0 0 64px;
    }

    label {
        font-family: $primary-font;
        display: flex;
        color: $gray5;
        align-items: center;
        text-transform: uppercase;
        font-weight: $bold;
        font-size: $fs1;

        & i {
            font-size: $fs05;
            margin-right: $x05;
        }
    }

    &.auto {
        flex: 0 0 auto !important;
    }

    & .input {
        margin: 0;
    }

    &:first-child {
        margin: 0;
    }

    &.xs {
        flex: 0 0 10% !important;
    }

    &.sm {
        flex: 0 0 15% !important;
    }

    &.md {
        flex: 0 0 20% !important;
    }

    &.lg {
        flex: 0 0 25% !important;
    }

    &.xl {
        flex: 0 0 30% !important;
    }
}

.input,
[class*="input-"] {
    display: flex;
}

.input {
    cursor: auto;
    transition: $animation-time;
    width: 100%;
    border: none;
    padding: 0;
    margin: 0 0 0 $x1;
    font-weight: $bold;
    text-transform: inherit;
    transition: $animation-time;
    z-index: 2;

    &:first-child {
        margin: 0;
    }

    &-calc {
        flex: 0 0 100px !important;
    }

    &-tax {
        flex: 0 0 90px !important;
    }

    &-ieps {
        flex: 0 0 50px !important;
    }

    &-currency {
        flex: 0 0 60px !important;
    }

    &-price {
        flex: 0 0 120px !important;
    }

    &-badge {
        position: relative;

        .badge {
            background-color: #fa3e3e;
            border-radius: 2px;
            color: white;

            padding: 1px 3px;
            font-size: 12px;

            position: absolute;
            /* Position the badge within the relatively positioned button */
            top: -10px;
            right: -10px;
        }
    }
}

.input input {
    width: 100%;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    color: $dark1;
    background: transparent;
    border: none;
    font-weight: $bold;
    font-family: $primary-font;

    &:focus {
        outline: none;
    }

    &.tar {
        text-align: right;
    }
}

.input textarea {
    width: 100%;
    margin: 0;
    padding: 0;
    color: $dark1;
    background: transparent;
    border: none;
    font-weight: $regular;
    font-family: $primary-font;
    height: 60px;
    line-height: 36px;
    padding: 0px 0px 10px 0px;

    &:focus {
        outline: none;
    }
}

.input-button {
    display: none;
}

.input-xs {
    height: $input-xs;
    border-radius: $brad-xs;
    font-size: $fs1;

    &-brad {
        &-left {
            border-radius: $brad-xs 0 0 $brad-xs;
        }

        &-right {
            border-radius: 0 $brad-xs $brad-xs 0;
        }
    }

    &-icon {
        height: $input-xs;
        width: $input-xs;
    }

    &-text {
        flex: 1;
        align-items: center;

        & input,
        & textarea {
            font-size: $fs1;
        }

        padding: 0 $x2;

        &.center {
            padding: 0;
        }

        &.right {
            padding: 0 $x2 0 0;
        }

        &.left {
            padding: 0 0 0 $x2;
        }
    }

    &-subtitle {
        flex: 0 0 auto;
        font-size: $fs1;
        align-items: center;
        text-transform: uppercase;
        padding: 0 $x2;
        font-weight: $bold;
    }
}

.input-sm {
    height: $input-sm;
    border-radius: $brad-sm;
    font-size: $fs2;

    &-brad {
        &-left {
            border-radius: $brad-sm 0 0 $brad-sm;
        }

        &-right {
            border-radius: 0 $brad-sm $brad-sm 0;
        }
    }

    &-icon {
        height: $input-sm;
        width: $input-sm;
    }

    &-text {
        flex: 1;
        align-items: center;

        & input,
        & textarea {
            font-size: $fs2;
        }

        padding: 0 $x3;

        &.center {
            padding: 0;
        }

        &.right {
            padding: 0 $x3 0 0;
        }

        &.left {
            padding: 0 0 0 $x3;
        }
    }

    &-subtitle {
        flex: 0 0 auto;
        font-size: $fs1;
        align-items: center;
        text-transform: uppercase;
        padding: 0 $x2;
        font-weight: $bold;
    }
}

.input-md {
    height: $input-md;
    border-radius: $brad-md;
    font-size: $fs3;

    &-brad {
        &-left {
            border-radius: $brad-md 0 0 $brad-md;
        }

        &-right {
            border-radius: 0 $brad-md $brad-md 0;
        }
    }

    &-icon {
        height: $input-md;
        width: $input-md;
    }

    &-select {
        height: $input-md;
        width: auto;
    }

    &-text {
        flex: 1;
        align-items: center;

        & input,
        & textarea {
            font-size: $fs3;
        }

        padding: 0 $x3;

        &.center {
            padding: 0;
        }

        &.right {
            padding: 0 $x3 0 0;
        }

        &.left {
            padding: 0 0 0 $x3;
        }
    }

    &-subtitle {
        flex: 0 0 auto;
        font-size: $fs1;
        align-items: center;
        text-transform: uppercase;
        padding: 0 $x2;
        font-weight: $bold;
    }
}

.input-lg {
    height: $input-lg;
    border-radius: $brad-lg;
    font-size: $fs4;

    &-brad {
        &-left {
            border-radius: $brad-lg 0 0 $brad-lg;
        }

        &-right {
            border-radius: 0 $brad-lg $brad-lg 0;
        }
    }

    &-icon {
        height: $input-lg;
        width: $input-lg;
    }

    &-text {
        flex: 1;
        align-items: center;

        & input,
        & textarea {
            font-size: $fs4;
        }

        padding: 0 $x3;

        &.center {
            padding: 0;
        }

        &.right {
            padding: 0 $x3 0 0;
        }

        &.left {
            padding: 0 0 0 $x3;
        }
    }

    &-subtitle {
        flex: 0 0 auto;
        font-size: $fs1;
        align-items: center;
        text-transform: uppercase;
        padding: 0 $x2;
        font-weight: $bold;
    }
}

.input-xl {
    height: $input-xl;
    border-radius: $brad-xl;
    font-size: $fs5;

    &-brad {
        &-left {
            border-radius: $brad-xl 0 0 $brad-xl;
        }

        &-right {
            border-radius: 0 $brad-xl $brad-xl 0;
        }
    }

    &-icon {
        height: $input-xl;
        width: $input-xl;
    }

    &-text {
        flex: 1;
        align-items: center;

        & input,
        & textarea {
            font-size: $fs5;
        }

        padding: 0 $x3;

        &.center {
            padding: 0;
        }

        &.right {
            padding: 0 $x3 0 0;
        }

        &.left {
            padding: 0 0 0 $x3;
        }
    }

    &-subtitle {
        flex: 0 0 auto;
        font-size: $fs1;
        align-items: center;
        text-transform: uppercase;
        padding: 0 $x2;
        font-weight: $bold;
    }
}

// widths
.input-search,
.input-autocomplete {
    width: 240px;

    &:focus-within {
        width: 280px;
    }
}

.input-select {
    width: 160px;

    &-xxs {
        width: 100px;
    }

    &-xs {
        width: 120px;
    }

    &-sm {
        width: 140px;
    }

    &-lg {
        width: 180px;
    }

    &-xl {
        width: 200px;
    }

    &-xxl {
        width: 220px;
    }
    border-radius: 10px;
}

.input-code {
    flex: 0 0 240px;
}

//colors
.input-gray-dark {
    color: $dark1;
    background: $gray3;

    &:hover,
    &:focus,
    &:focus-within {
        background: $gray4;
    }

    & i {
        background: $gray3;
    }
}

.input-white {
    color: $dark1;
    background: $white;

    &:hover,
    &:focus,
    &:focus-within {
        background: $gray1;
    }

    & i {
        background: $white;
    }
}

.input-gray {
    color: $dark1;
    background: $gray2;

    &:hover,
    &:focus,
    &:focus-within {
        background: $gray3;
    }

    & i {
        background: $gray2;
    }
}

.input-alert-light {
    color: $alert-dark;
    background: $alert-light;

    & input,
    & textarea {
        color: $alert-dark;
    }

    & ::placeholder {
        color: $alert-alt;
    }

    &:hover,
    &:focus,
    &:focus-within {
        transition: $animation-time;
        background: $alert-alt;
    }

    & i {
        background: $alert-light;
    }
}

.input-warn-light {
    color: $warn-dark;
    background: $warn-light;

    & input,
    & textarea {
        color: $warn-dark;
    }

    & ::placeholder {
        color: $warn-alt;
    }

    &:hover,
    &:focus,
    &:focus-within {
        transition: $animation-time;
        background: $warn-alt;
    }

    & i {
        background: $warn-light;
    }
}

.input-danger-light {
    color: $danger-dark;
    background: $danger-light;

    & input,
    & textarea {
        color: $danger-dark;
    }

    & ::placeholder {
        color: $danger-alt;
    }

    &:hover,
    &:focus,
    &:focus-within {
        transition: $animation-time;
        background: $danger-alt;
    }

    & i {
        background: $danger-light;
    }
}

.input-primary {
    color: $primary-dark;
    background: $primary-light;

    & input,
    & textarea {
        color: $primary-dark;
    }

    & ::placeholder {
        color: $primary-alt;
    }

    &:hover,
    &:focus,
    &:focus-within {
        transition: $animation-time;
        background: $primary-alt;
    }

    & i {
        background: $primary-light;
    }
}

.input-success-light {
    color: $success-dark;
    background: $success-light;

    & input,
    & textarea {
        color: $success-dark;
    }

    & ::placeholder {
        color: $success-alt;
    }

    &:hover,
    &:focus,
    &:focus-within {
        transition: $animation-time;
        background: $success-alt;
    }

    & i {
        background: $success-light;
    }
}

.input-disabled {
    color: $gray4;
    background: $gray2;
}

//fixes
::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
    background: $white;
}

::ng-deep .mat-form-field,
::ng-deep .mat-checkbox {
    display: flex;
    align-items: center;
}

::ng-deep .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.input-checkbox {
    .mat-checkbox-layout {
        margin-bottom: 0px;
    }
}

// Display 4k(2560px or above)
@media (max-width: $display-xl) {}

// Display FullHD (1920px or above)
@media (max-width: $display-lg) {}

// Display Tablet Landscape (1119px or below)
@media (max-width: $display-md) {}

// Display Tablet Portrait (1023px or below)
@media (max-width: $display-sm) {
    .inputs-row {
        flex-flow: column;

        & .input-container {
            margin-left: 0;
            margin-top: 8px;
        }
    }
}

// Display Mobile (767px or below)
@media (max-width: $display-xs) {}