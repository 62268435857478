// Empty States Framework

.empty-state,
[class*="empty-state-"] {
    display: flex;
}

.empty-state {
    flex-flow: column;
    align-items: center;
    flex: 1 1 auto;
    height: 100vh;
    justify-content: center;
    background: $gray2;
    margin: 20px;
    border-radius: 10px;

    i {
        margin: 0 0 $y-space2 0;
    }

    h1,
    p {
        color: $white;
        margin: 0 0 $y-space2 0;
        text-align: center;
    }

    h1 {
        font-size: $size7;
    }

    button {
        margin: $y-space2 0;
    }

    .lg {
        font-size: 32px;
    }
}