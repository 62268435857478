// rich text tables
.rich-text-table {
  table {
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 1px solid $transparent10;
    // padding: 2px;
    padding: 0.5rem;
  }
  th {
    text-align: center;
    vertical-align: middle;
    // background: $transparent50;
  }
  td {
    text-align: center;
    vertical-align: middle;
  }
  tr:hover {
    background-color: $gray2;
  }
}
