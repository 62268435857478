// Errors framework

.errors,
[class*="errors-"] {
  display: flex;
}

.errors {
  min-height: 100vh;
  flex: 1 1 auto;
  flex-flow: column;
  align-items: center;
  img {
    margin: $y-space6 $x-space5;
  }
  &-card {
    flex-flow: column;
    text-align: center;
    align-items: center;
    padding: $y-space6 $x-space10;
    background: $white;
    border-radius: $brad4;
    animation: error2 2s linear infinite;
    @keyframes error2 {
      0% {
        box-shadow: none;
      }
      50% {
        box-shadow: $shad5;
      }
      100% {
        box-shadow: none;
      }
    }
    i {
      animation: error 2s linear infinite;
      @keyframes error {
        0% {
          background: $white;
        }
        50% {
          background: $warn-light;
        }
        100% {
          background: $white;
        }
      }
    }
    h2 {
      color: $warn;
      text-transform: uppercase;
      margin: $y-space2 0;
    }
    h1 {
      margin-bottom: $y-space3;
    }
    p {
      margin-bottom: $y-space3;
      font-size: $size4;
    }
  }
}
